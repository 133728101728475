import { useLayoutEffect, useState } from 'react';
import Retool from 'react-retool';
import { RotatingLines } from 'react-loader-spinner';
import restClient from '../lib/rest-client';
import useStore from '../lib/store';

export default function SCMBG(props) {
  const [embedUrl, setEmbedUrl] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const user = useStore((state) => state.user);
  const environment = useStore((state) => state.environment);
  const isImpersonating = useStore((state) => state.isImpersonating);

  const handleRetoolEvents = (event) => {
    // The loader is hidden when message is posted from retool.
    // Retool posts message before rendering happens, so waiting for few more seconds before hiding the loader
    setTimeout(() => {
      setShowLoader(false);
    }, 5000);
  };

  const getEmbedUrl = async () => {
    if (user) {
      try {
        const response = await restClient.post('/embedlink', {
          app_name: 'scm_bg',
          environment: environment,
          metadata: {
            userEmail: user.profile?.email,
          },
        });
        let embedUrl = response.data.embedUrl;
        setEmbedUrl(embedUrl);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useLayoutEffect(() => {
    getEmbedUrl();
  }, [environment, isImpersonating, user]);

  return (
    <>
      <div className='App'>
        <div className={showLoader ? 'App-overlay' : ''}>
          <div className='App-loader'>
            <RotatingLines
              strokeColor='white'
              strokeWidth='5'
              animationDuration='0.75'
              width='96'
              visible={showLoader}
            />
          </div>
        </div>
        <div className='App-content'>
          {embedUrl && (
            <Retool
              url={embedUrl}
              data={{}}
              onData={(e) => {
                handleRetoolEvents(e);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
